<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import DfTabs from '/@elements/DfTabs/DfTabs.vue';
import { useUserStore } from '/@stores/user';
import { Modules, Roles } from '/@types/ids';
import { hasRole } from '/@utilities/user';

import ProjectAdminListPage from '/@pages/ProjectAdminListPage/ProjectAdminListPage.vue';
import ResourceGroupsPage from '/@pages/ResourceGroupsPage/ResourceGroupsPage.vue';
import UserListPage from '/@pages/UserListPage/UserListPage.vue';
import UserProfileTimer from '/@pages/UserProfilePage/UserProfileTimer.vue';

const props = defineProps<{
  tab: string;
}>();

const router = useRouter();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const activeTab = ref(props.tab || null);
const tabs = computed(() =>
  (userStore.hasModule(Modules.Timer) || userStore.hasModule(Modules.Ressurs)
    ? [{ id: 'bruker', name: 'Innstillinger' }]
    : []
  )
    .concat([
      { id: 'grupper', name: 'Grupper' },
      { id: 'ra', name: 'Ressursansvarlige' },
    ])
    .concat(
      user.value != null && hasRole(user.value, Roles.RessursAnsvarlig)
        ? [{ id: 'admpro', name: 'Administrative prosjekter' }]
        : [],
    ),
);

watch(activeTab, (tab) => router.replace({ params: { tab } }));
</script>

<template>
  <div class="page page--wide">
    <df-tabs :tabs="tabs" v-model="activeTab">
      <template #tab:bruker>
        <UserProfileTimer />
      </template>

      <template #tab:grupper>
        <ResourceGroupsPage />
      </template>

      <template #tab:admpro>
        <ProjectAdminListPage />
      </template>

      <template #tab:ra>
        <UserListPage />
      </template>
    </df-tabs>
  </div>
</template>

<style scoped></style>
