import { defineStore } from 'pinia';
import { api, genericApiErrorHandler } from '/@utilities/api';
import { mergeMap } from '/@utilities/general';
import { hasTenantFeature, TenantFeatures } from '/@utilities/tenant';
import { ref, computed } from 'vue';

interface Caseworker {
  userName: string;
  userId: number;
  email: string;
}

function mapCaseworker(caseworker: any): Caseworker {
  return {
    userName: caseworker.FullName,
    userId: caseworker.UserId,
    email: caseworker.Email,
  };
}

interface TenantUser {
  id: number;
  guid: string;
  name: string;
  roles: Array<{ id: number; name: string }>;
  phone: string;
  email: string;
}

function mapTenantUser(res: any): TenantUser {
  return {
    id: res.UserId,
    guid: res.UserGuid,
    name: `${res.FirstName} ${res.LastName}`,
    roles: res.Roles.map((r: any) => ({ id: r.Id, name: r.Name })),
    phone: res.Mobile,
    email: res.Email,
  };
}

type Tenant = {
  id: number;
  tenantFeatures: string[];
  departments: Map<number, { name: string }>;
  startTime: { hours: number; minutes: number };
  endTime: { hours: number; minutes: number };
  totalWorkingHours: number;
  elsmartIntegration: boolean;
};

let resolveTenant: (value?: unknown) => void;
export const awaitTenant = new Promise((resolve) => (resolveTenant = resolve));

export const useTenantStore = defineStore('tenant', () => {
  const isIntegration = computed(() => {
    return hasTenantFeature(tenant.value, TenantFeatures.DynamicsIntegration);
  });

  const tenant = ref<Tenant | null>(null);

  function loadTenant() {
    return api
      .get('shared/users/currenttenant')
      .then(({ data }) => {
        tenant.value = {
          id: data.Id,
          tenantFeatures: data.TenantFeatures,
          departments: new Map(data.Departments.map((d: any) => [d.Id, { name: d.Name }])),
          startTime: { hours: data.StartTime.Hours, minutes: data.StartTime.Minutes },
          endTime: { hours: data.EndTime.Hours, minutes: data.EndTime.Minutes },
          totalWorkingHours: data.TotalWorkingHours,
          elsmartIntegration: data.TenantFeatures.includes(TenantFeatures.ElsmartIntegration),
        };

        resolveTenant();
      })
      .catch(genericApiErrorHandler);
  }

  const caseworkers = ref<Map<number, Caseworker>>(new Map());

  function loadCaseworkers(): Promise<Map<number, Caseworker>> {
    return api
      .get(`v1/project/caseworkers`)
      .then(({ data }) => {
        caseworkers.value = mergeMap(
          caseworkers.value,
          new Map(data.map((c: any) => [c.UserId, mapCaseworker(c)])),
        );
        return caseworkers.value;
      })
      .catch(genericApiErrorHandler);
  }

  const installers = ref<Map<number, { id: number; name: string }>>(new Map());

  function loadInstallers(): Promise<void> {
    return api
      .get('v1/project/installers')
      .then(({ data }) => {
        installers.value = new Map(
          data.map((i: any) => [i.UserId, { id: i.UserId, name: i.FullName }]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  const users = ref<Map<number, TenantUser>>(new Map());

  function loadUsers() {
    return api
      .get(`/users`)
      .then(({ data }) => {
        users.value = new Map(
          data
            .map((user: any) => [user.UserId, mapTenantUser(user)])
            .sort((a: any, b: any) => a[1].name.localeCompare(b[1].name)),
        );
      })
      .catch(genericApiErrorHandler);
  }
  const qualifications = ref(new Map());

  function loadQualifications() {
    return api
      .get(`/qualifications`)
      .then(({ data }) => {
        qualifications.value = mergeMap(
          qualifications.value,
          new Map(data.map((d: any) => [d.Id, d.Name])),
        );
      })
      .catch(genericApiErrorHandler);
  }

  const departments = ref<Map<number, string>>(new Map());

  function loadDepartments(): Promise<Map<number, string>> {
    return api
      .get(`/departments`)
      .then(({ data }) => {
        departments.value = new Map(data.map((d: any) => [d.Id, d.Name]));
        return departments.value;
      })
      .catch(genericApiErrorHandler);
  }

  type Module = { projectTypeIds: Set<number> };

  const modules = ref<Map<number, Module>>(new Map());

  function loadModules() {
    return api
      .get(`/tenants/${tenant.value?.id}/modules`)
      .then(({ data }) => {
        modules.value = new Map(
          data.map((m: any) => [
            m.Id,
            { projectTypeIds: new Set(m.ProjectTypes.map((pt: any) => pt.Id)) },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function getDefaultWorkingHours() {
    return {
      startTime: {
        hours: tenant.value?.startTime.hours ?? 8,
        minutes: tenant.value?.startTime.minutes ?? 0,
      },
      endTime: {
        hours: tenant.value?.endTime.hours ?? 16,
        minutes: tenant.value?.endTime.minutes ?? 0,
      },
    };
  }

  return {
    tenant,
    loadTenant,

    users,
    loadUsers,

    caseworkers,
    loadCaseworkers,

    qualifications,
    loadQualifications,

    departments,
    loadDepartments,

    isIntegration,

    installers,
    loadInstallers,

    modules,
    loadModules,

    getDefaultWorkingHours,
  };
});
